<template>
	<div>
		<a-layout>
			<a-layout-sider class="content-left-menu" width="200px">
				<div style="padding: 30px 20px;">
						<div v-for="(item,index) in menus" :key="index" @click="goToUrl(index)" class="second-menu-item " :class="{on:getSelectKey.indexOf(item.key) > -1}">
							<a-icon :type="item.icon" theme="twoTone" />
							<span class="ft14 ml10">{{item.name}}</span>

						</div>					
				</div>
			</a-layout-sider>
			<a-layout-content class="ml15">
				<div>
					<router-view></router-view>
				</div>
			</a-layout-content>
		</a-layout>
		
	</div>
</template>

<script>
	export default {
		
		data() {
			return {
				showForm:false,
				order_num:0,
				menus: [
					
					{
						name: '发票列表',
						icon: 'profile',
						key: 'invoice_list',
						link: '/invoice'
					},
					// {
					// 	name: '文章分类',
					// 	icon: 'appstore',
					// 	key: 'article_category',
					// 	link: '/article/category'
					// }
				],
			}
		},
		methods: {
			goToUrl(index) {
				this.refreshPage(this.menus[index].link);
			},
		}
	}
</script>
